import React from "react"
import { graphql } from "gatsby"
import { Marker } from "react-map-gl"

const PostMapMarker = ({ data, onClick }) => {
  var iconClassName = "fa fa-heart"
  var extraStyle = {}
  if (data.frontmatter.category === "sights") {
    iconClassName = "fa fa-eye"
    extraStyle = { marginLeft: 1, marginTop: -1 }
  } else if (data.frontmatter.category === "sounds") {
    iconClassName = "fa fa-music"
    extraStyle = { marginLeft: -3, marginTop: -1 }
  } else if (data.frontmatter.category === "stories") {
    iconClassName = "fa fa-paper-plane"
    extraStyle = { marginLeft: -3 }
  } else if (data.frontmatter.category === "picks") {
    iconClassName = "fa fa-star"
  }

  return (
    <Marker
      latitude={data.frontmatter.latitude}
      longitude={data.frontmatter.longitude}
    >
      <div className="globe-marker" onClick={onClick}>
        <span className={iconClassName} style={extraStyle} />
      </div>
    </Marker>
  )
}
export default PostMapMarker

export const query = graphql`
  fragment PostMapMarkerFragment on MarkdownRemark {
    id
    frontmatter {
      title
      category
      latitude
      longitude
    }
    excerpt
    fields {
      slug
    }
  }
`
