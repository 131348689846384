import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { layoutSmallPosts } from "../components/smallpostpreview"
import SEO from "../components/seo"
import "mapbox-gl/dist/mapbox-gl.css"
import PostsMap from "../components/postsmap"
import { navigate } from "@reach/router"

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { posts } = data.allMarkdownRemark

  const mapPosts = posts.filter(
    post =>
      post.frontmatter.latitude !== null && post.frontmatter.longitude !== null
  )

  return (
    <Layout>
      <SEO
        title={tag}
        // description={data.post.frontmatter.excerpt}
        // thumbnail={data.post.frontmatter.image.thumbnail.fixed.src}
      />
      <div className="content-area">
        <main className="site-main">
          <div className="container-fluid">
            <div className="row center-xs">
              <div className="col-xs12 no-padd ">
                <header className="tag-header">
                  <h6 className="entry-category">
                    <a href="#">{tag}</a>
                  </h6>
                </header>
              </div>
            </div>
          </div>

          <div className="bg-c-1 padd-xs-b60 padd-lg-b60">
            <div className="container padd-only-sm">
              <div className="row">
                <div className="container">
                  <div className="xs-12">{layoutSmallPosts(posts)}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-xs12 no-padd">
                <PostsMap
                  posts={mapPosts}
                  onPostClicked={post => navigate(post.fields.slug)}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      posts: nodes {
        ...PostMapMarkerFragment
        ...SmallPostPreviewFragment
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`
