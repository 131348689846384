import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import ShareWidget from "./sharewidget"

const SmallPostPreview = ({ data }) => (
  <article className="c-post c-small-post">
    <div className="post-img-link-wrapper">
      <Link to={data.fields.slug} className="post-img-link">
        {data.frontmatter.image && (
          <Img
            fluid={data.frontmatter.image.smallPreviewImage.fluid}
            className="img-responsive"
            alt=""
          />
        )}
      </Link>
    </div>

    <header className="entry-header">
      <h6 className="entry-category">
        <Link to={`/${data.frontmatter.category}`}>
          {data.frontmatter.category}
        </Link>
      </h6>
      <h2 className="entry-title">
        <Link to={data.fields.slug}>{data.frontmatter.title}</Link>
      </h2>
    </header>

    <div className="entry-content">
      <p>{data.excerpt}</p>
    </div>

    <footer className="entry-footer clearfix">
      <div className="share-links">
        <i>SHARE</i>
        <ShareWidget slug={data.fields.slug} />
      </div>
    </footer>
  </article>
)

export default SmallPostPreview

export const layoutSmallPosts = posts => {
  let ret = []

  if (posts.length === 0) {
    return <div />
  }

  let remaining = posts
  do {
    let [post1, post2] = remaining

    ret = ret.concat(
      <div
        className="row marg-lg-t50"
        key={`${post1.id}-${post2 ? post2.id : "null"}`}
      >
        <div className="col-xs-12 col-sm-6 between-sm">
          <SmallPostPreview data={post1} />
        </div>
        {post2 && (
          <div className="col-xs-12 col-sm-6 marg-xs-t40">
            <SmallPostPreview data={post2} />
          </div>
        )}
      </div>
    )
    remaining = remaining.slice(2)
  } while (remaining.length > 0)
  return ret
}

export const query = graphql`
  fragment SmallPostPreviewFragment on MarkdownRemark {
    id
    frontmatter {
      title
      category
      image {
        smallPreviewImage: childImageSharp {
          # Force aspect ratio to 16/10
          fluid(maxWidth: 800, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tags
    }
    excerpt
    fields {
      slug
    }
  }
`
