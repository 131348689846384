import "mapbox-gl/dist/mapbox-gl.css"
import React, { useState, useEffect } from "react"
import ReactMapGL, { Popup } from "react-map-gl"
import WebMercatorViewport from "viewport-mercator-project"
import PostMapMarker from "./postmapmarker"

const PostsMap = ({ onPostClicked, posts }) => {
  const [viewport, updateViewport] = useState(prepareInitialViewport(posts))
  useEffect(() => {
    const onResize = () => {
      updateViewport(viewport => ({ ...viewport, width: window.innerWidth }))
    }

    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  })

  return (
    <ReactMapGL
      className="posts-map"
      mapStyle="https://api.maptiler.com/maps/voyager/style.json?key=frABpSWILD6MrLBg16bM"
      {...viewport}
      onViewportChange={newViewport => updateViewport(newViewport)}
      onClick={() => onPostClicked(null)}
    >
      {posts.map(node => (
        <PostMapMarker
          key={node.id}
          data={node}
          onClick={() => onPostClicked(node)}
        />
      ))}
    </ReactMapGL>
  )
}

function prepareInitialViewport(posts) {
  const windowInfo = {
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height: 500,
  }
  if (posts.length === 0 || typeof window === "undefined") {
    return {
      ...windowInfo,
      latitude: 0,
      longitude: 0,
      zoom: 4,
    }
  } else if (posts.length === 1) {
    return {
      ...windowInfo,
      latitude: posts[0].frontmatter.latitude,
      longitude: posts[0].frontmatter.longitude,
      zoom: 8,
    }
  } else {
    const minLatitude = Math.min(
      ...posts.map(post => post.frontmatter.latitude)
    )
    const maxLatitude = Math.max(
      ...posts.map(post => post.frontmatter.latitude)
    )
    const minLongitude = Math.min(
      ...posts.map(post => post.frontmatter.longitude)
    )
    const maxLongitude = Math.max(
      ...posts.map(post => post.frontmatter.longitude)
    )

    return new WebMercatorViewport(windowInfo).fitBounds(
      [[minLongitude, minLatitude], [maxLongitude, maxLatitude]],
      {
        padding: 50,
        offset: [0, 0],
      }
    )
  }
}
export default PostsMap
